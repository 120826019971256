import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-generic-groove-wrapper',
    templateUrl: './generic-groove-wrapper.component.html',
    styleUrls: ['./generic-groove-wrapper.component.scss']
})
export class GenericGrooveWrapperComponent implements OnInit {
    defaultValue: any = null;

    @Input()
    value: any;

    @Input()
    fieldName: string = '';

    @Input()
    label: string = '';

    @Input()
    isDisabled: boolean = false;

    @Input()
    placeholder: string = '';

    @Input()
    isRequired: boolean = false;

    @Input()
    customValidatorsWithMessages: { validatorName: string, validator: ValidatorFn }[] = [];

    customValidators: string[] = []

    @Output()
    valueChanged = new EventEmitter<any>(true);

    @Output()
    touched = new EventEmitter<boolean>(true);

    form: UntypedFormGroup;

    requiredErrorMessage: string = '';

    constructor(
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.requiredErrorMessage = this.translateService.instant('enrollment.forms.errors.fieldIsRequired', { fieldName: this.label });

        const validators: ValidatorFn[] = [];

        this.customValidatorsWithMessages.forEach((customValidator) => {
           if (customValidator.validator) {
               validators.push(customValidator.validator);
               this.customValidators.push(customValidator.validatorName);
           }
        });

        if (this.isRequired) {
            validators.push(Validators.required);
            validators.push(this.required());
            this.customValidators.push('required');
        }

        if (this.fieldName === 'employeeId' || this.fieldName === 'birthYear') {
            this.defaultValue = '';
        }

        let fields = {};
        fields[this.fieldName] = new UntypedFormControl({
            value: this.value ? this.value : this.defaultValue,
            disabled: this.isDisabled
        }, validators);

        this.form = new UntypedFormGroup(fields);

        if (this.value) {
            this.valueChanged.emit(this.value);
        }

        this.form.valueChanges.subscribe((newValue: any) => {
            if (this.value !== newValue) {
                this.valueChanged.emit(newValue[this.fieldName]);
            }
        });
    }

    onBlur() {
        this.touched.emit(true);
    }

    private required(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return { required: 'Invalid', message: this.requiredErrorMessage };
            }

            return null;
        };
    }
}
